import SingletonApp from './SingletonApp.ts';
import GlobalHoverServiceApp from './HoverService.GlobalHoverServiceApp.vue';
import { reactive, markRaw, nextTick } from 'vue';

declare global {
    interface Window {
        /** Global hover service used for directives like person-hover */
        __HoverService__?: GlobalHoverService;
    }
}

export default class GlobalHoverService {
    /** Private internal construction check */
    static #isInternalConstructing: boolean;
    /** Get the service instance */
    private static _instance: GlobalHoverService;
    /** Get the service instance */
    static getService() {
        if (!GlobalHoverService._instance) {
            GlobalHoverService.#isInternalConstructing = true;
            GlobalHoverService._instance = new GlobalHoverService();
            GlobalHoverService.#isInternalConstructing = false;
        }
        window['__HoverService__'] = GlobalHoverService._instance;
        return GlobalHoverService._instance;
    }

    private _hoverCache: Record<string, { el: HTMLElement, options: any }> = {};
    private _isMounted: boolean = false;
    private _showDebounce: number | null = null;
    private _hideDebounce: number | null = null;
    private _cleanupDebounce: number | null = null;
    private app: SingletonApp;
    state: GlobalHoverServiceState;

    constructor() {
        if (!GlobalHoverService.#isInternalConstructing) {
            throw new TypeError('GlobalHoverService is not constructable, use GlobalHoverService.getService()');
        }

        this.state = reactive(new GlobalHoverServiceState());
        this.app = SingletonApp.getOrCreateApp({
            id: 'o365-global-hover-app',
            appComponent: GlobalHoverServiceApp,
            provides: { 'GlobalHoverService': GlobalHoverService }
        });

        window.addEventListener("pageshow", () => {
            if (this._showDebounce) { window.clearTimeout(this._showDebounce); this._showDebounce = null; }
            if (this._hideDebounce) { window.clearTimeout(this._hideDebounce); this._hideDebounce = null; }

            this.hide();
        });
    }

    /** Binds element with popover */
    bindElement(el: HTMLElement, options: IHoverBindOptions) {
        const uid = window.crypto['randomUUID']();
        options.component = markRaw(options.component);
        this._hoverCache[uid] = { el: el, options: options };
        el.dataset.globalHoverUid = uid;
        
        el.addEventListener('mouseenter', (_e: MouseEvent) => {
            if (this._showDebounce) { window.clearTimeout(this._showDebounce); this._showDebounce = null; }
            this._showDebounce = window.setTimeout(() => {
                this.show(uid);
                this._showDebounce = null;
            }, options.timeout ?? 250);
        })

        el.addEventListener('mouseleave', (_e: MouseEvent) => {
            if (this._showDebounce) { window.clearTimeout(this._showDebounce); this._showDebounce = null; }
            if (this.state.activeUid !== uid || !this.state.render) { return; }

            if (this._hideDebounce) { window.clearTimeout(this._hideDebounce); this._hideDebounce = null; }
            this._hideDebounce = window.setTimeout(() => {
                if (this._disableHide) { return; }
                this.hide();
                this._hideDebounce = null;
            }, 250);
        });
    }

    /** Unbinds element with the popover */
    unbindElement(el: HTMLElement) {
        const uid = el.dataset.globalHoverUid;
        if (uid == null) { console.warn('Failed to extract hoved uid from element', el); return; }
        delete this._hoverCache[uid];
    }

    private _disableHide: boolean = false;
    /** Bind popper freeze event */
    _initPopperEvents(container: HTMLElement) {
        container.addEventListener('mouseover', () => {
            this._disableHide = true;
        });
        container.addEventListener('mouseleave', () => {
            this._disableHide = false;
            if (this._hideDebounce) { window.clearTimeout(this._hideDebounce); this._hideDebounce = null; }
            this._hideDebounce = window.setTimeout(() => {
                if (this._disableHide) { return; }
                this.hide();
                this._hideDebounce = null;
            }, 250);
        });
    }


    /** Sets the options  */
    async show(uid: string) {
        const options = this._hoverCache[uid];
        if (options == null) { console.warn('Could not retrieve options for given uid'); return; }
        if (this._hideDebounce) { window.clearTimeout(this._hideDebounce); this._hideDebounce = null; }
        if (this._cleanupDebounce) { window.clearTimeout(this._cleanupDebounce); this._cleanupDebounce = null; }
        this.state.options = options.options;
        if (this._isMounted && !this.state.render) {
            this.state.render = true;
            await nextTick();
        } else {
            this.state.render = true;
        }
        this.state.show = true;
        this.state.activeUid = uid;
        this._mountCheck();
    }

    hide() {
        this.state.show = false;
        if (this._cleanupDebounce) { window.clearTimeout(this._cleanupDebounce); }
        this._cleanupDebounce = window.setTimeout(() => {
            this.state.render = false;
            this._cleanupDebounce = null;
        }, 250);
    }

    getElementFromUid(uid: string) {
        return this._hoverCache[uid]?.el;
    }

    getPropsFromEl(el: HTMLElement) {
        const uid = el.dataset.globalHoverUid;
        if (uid == null) { console.warn('Failed to extract hoved uid from element', el); return; }
        if (this._hoverCache[uid] == null) { console.warn('Could not get the hover cache for updating props'); return; }
        return this._hoverCache[uid].options.props;
    }

    private _mountCheck() {
        if (this._isMounted) { return; }
        this._isMounted = true;
        this.app.mount();
    }
}

class GlobalHoverServiceState {
    options?: { component: any, props: any };
    popperProps: any;
    activeUid?: string;
    render: boolean = false;
    show: boolean = false;
}

export interface IHoverBindOptions {
    component: any;
    props: any;
    timeout?: number;
}